<template>
    <div class="mt-4 wrapper flex gap-10 flex-row relative">
        <div class="ranked-icon">
            <img :src="icon" alt="Ranked icon" width="150" />
        </div>
        <div class="ranked-overlay" :style="{'background-color': progressColour}">
            <div class="flex flex-col w-full" v-if="currentSr >= 0 && currentSr < 10000">
                <div class="progress-bar-container">
                    <div class="progress-bar">
                        <div class="progress" :style="{'width': progressWidth + '%'}"></div>
                        <div class="divider"></div>
                        <div class="divider"></div>
                    </div>
                </div>
                <div class="flex mt-1 justify-between bg-white px-2 font-bold rounded">
                    <div class="flex flex-row gap-2 items-center">
                        <p class="bg-white p-1 rounded uppercase ">SR</p>
                        <p>{{ currentSr }}</p>
                    </div>
                    <div class="flex flex-row gap-2 items-center">
                        <p class="calculated-sr" :class="{'text-green': calculatedSR > 0, 'text-red': calculatedSR < 0}">
                            <span class="align-text-bottom" v-if="calculatedSR > 0">+</span>{{ calculatedSR }}
                        </p>
                    </div>
                    <div class="flex flex-row gap-2 items-center">
                        <p class="uppercase">{{ currentRank }}</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row mx-auto gap-2 items-center bg-white p-2 rounded w-full font-bold" v-if="currentSr >= 10000">
                <p class="bg-white p-1 rounded uppercase w-full">SR</p>
                {{ currentSr }}
            </div>
        </div>
    </div>
</template>

<script>
//import apiClient from '@/http-common'
import {authStore} from '@/stores/AuthStore';
import apiClient from "@/http-common";
import {getPusherChannel} from "@/services/PusherService";

export default {
    name: 'RankedOverlay',
    computed: {
        calculatedSR() {
            return this.currentSr - this.startingSr;
        }
    },
    data() {
        return {
            currentRank: 'Bronze',
            icon: '/assets/bronze1.png',
            progressColour: '#896F58',
            progressWidth: 0,
            currentSr: 0,
            startingSr: 0,
            userId: null,
        }
    },
    watch: {
        currentSr() {
            return this.processCurrentRank();
        }
    },
    mounted() {
        const auth = authStore();

        if (!auth.userId) {
            this.userId = this.$route.params.userId;
        } else {
            this.userId = auth.userId;
        }

        // Prioritise seeing the overlay you have requested in the browser. Even if logged in.
        // First condition is for when component is nested in the dashboard.
        if ((this.$route.params.userId) && auth.userId !== this.$route.params.userId) {
            this.userId = this.$route.params.userId;
        }

        const vue = this;
        const channel = getPusherChannel();
        channel.bind(`sr-update-${this.userId}`, function(data) {
            if (data.currentSr) {
                vue.currentSr = data.currentSr;
            }
            if (data.startingSr) {
                vue.startingSr = data.startingSr;
            }
        });

        this.getComputedResults();
    },
    methods: {
        getComputedResults() {
            apiClient.get(`${this.userId}/fetch-data`)
            .then((res) => {
                if (res.status !== 200) {
                    alert(res.statusText);
                    return false;
                }

                this.currentSr = res.data.currentSr;
                this.startingSr = res.data.startingSr;
                this.processCurrentRank();
            })
            .catch(() => {
                // Do nothing.
            })
        },
        processCurrentRank() {
            // Bronze
            if (this.currentSr >= 0 && this.currentSr <= 299) {
                return this.setProgress('bronze1');
            }

            if (this.currentSr >= 300 && this.currentSr <= 599) {
                return this.setProgress('bronze2');
            }

            if (this.currentSr >= 600 && this.currentSr <= 899) {
                return this.setProgress('bronze3');
            }

            // Silver
            if (this.currentSr >= 900 && this.currentSr <= 1299) {
                return this.setProgress('silver1');
            }

            if (this.currentSr >= 1300 && this.currentSr <= 1699) {
                return this.setProgress('silver2');
            }

            if (this.currentSr >= 1700 && this.currentSr <= 2099) {
                return this.setProgress('silver3');
            }

            // Gold
            if (this.currentSr >= 2100 && this.currentSr <= 2599) {
                return this.setProgress('gold1');
            }

            if (this.currentSr >= 2600 && this.currentSr <= 3099) {
                return this.setProgress('gold2');
            }

            if (this.currentSr >= 3100 && this.currentSr <= 3599) {
                return this.setProgress('gold3');
            }

            // Platinum
            if (this.currentSr >= 3600 && this.currentSr <= 4199) {
                return this.setProgress('platinum1');
            }

            if (this.currentSr >= 4200 && this.currentSr <= 4799) {
                return this.setProgress('platinum2');
            }

            if (this.currentSr >= 4800 && this.currentSr <= 5399) {
                return this.setProgress('platinum3');
            }

            // Diamond
            if (this.currentSr >= 5400 && this.currentSr <= 6099) {
                return this.setProgress('diamond1');
            }

            if (this.currentSr >= 6100 && this.currentSr <= 6799) {
                return this.setProgress('diamond2');
            }

            if (this.currentSr >= 6800 && this.currentSr <= 7499) {
                return this.setProgress('diamond3');
            }

            // Crimson
            if (this.currentSr >= 7500 && this.currentSr <= 8299) {
                return this.setProgress('crimson1');
            }

            if (this.currentSr >= 8300 && this.currentSr <= 9099) {
                return this.setProgress('crimson2');
            }

            if (this.currentSr >= 9100 && this.currentSr <= 9999) {
                return this.setProgress('crimson3');
            }

            // Iridescent
            if (this.currentSr >= 10000) {
                return this.setProgress('iridescent1');
            }
        },
        getProgressBackgroundColour(colour) {
            colour = colour.slice(0, -1)
            switch (colour) {
                case 'silver':
                    return '#756E6B';
                case 'gold':
                    return '#F0E490';
                case 'platinum':
                    return '#28B4A2';
                case 'diamond':
                    return '#0B578B';
                case 'crimson':
                    return '#89070B';
                case 'iridescent':
                    return '#AC4FCB';
                default:
                    return '#896F58'; // Bronze
            }
        },
        getIconPath(icon) {
            switch (icon) {
                case 'bronze2':
                    return '/assets/bronze2.png';
                case 'bronze3':
                    return '/assets/bronze3.png';
                case 'silver1':
                    return '/assets/silver1.png';
                case 'silver2':
                    return '/assets/silver2.png';
                case 'silver3':
                    return '/assets/silver3.png';
                case 'gold1':
                    return '/assets/gold1.png';
                case 'gold2':
                    return '/assets/gold2.png';
                case 'gold3':
                    return '/assets/gold3.png';
                case 'platinum1':
                    return '/assets/platinum1.png';
                case 'platinum2':
                    return '/assets/platinum2.png';
                case 'platinum3':
                    return '/assets/platinum3.png';
                case 'diamond1':
                    return '/assets/diamond1.png';
                case 'diamond2':
                    return '/assets/diamond2.png';
                case 'diamond3':
                    return '/assets/diamond3.png';
                case 'crimson1':
                    return '/assets/crimson1.png';
                case 'crimson2':
                    return '/assets/crimson2.png';
                case 'crimson3':    
                    return '/assets/crimson3.png';
                case 'iridescent1':
                    return '/assets/iridescent.png';
                case 'bronze1':
                default:
                    return '/assets/bronze1.png'; // Bronze
            }
        },
        getProgressWidth(rank) {
            rank = rank.slice(0, -1)
            switch (rank) {
                case 'silver':
                    return this.calculateProgress(900, 2099);
                case 'gold':
                    return this.calculateProgress(2100, 3599);
                case 'platinum':
                    return this.calculateProgress(3600, 5399);
                case 'diamond':
                    return this.calculateProgress(5400, 7499);
                case 'crimson':
                    return this.calculateProgress(7500, 9999);
                case 'iridescent':
                    return this.calculateProgress(10000, 30000);
                default:
                    return this.calculateProgress(0, 899); // Bronze
            }
        },
        calculateProgress(lowerLimit, upperLimit) {
            return ((this.currentSr - lowerLimit) / (upperLimit - lowerLimit)) * 100;
        },
        setProgress(rank) {
            this.currentRank = rank;
            this.icon = this.getIconPath(rank);
            this.progressColour = this.getProgressBackgroundColour(rank);
            this.progressWidth = this.getProgressWidth(rank);
        },
    }
}
</script>
// pusher.js (Singleton pattern for Pusher)
import Pusher from 'pusher-js';

let pusherInstance = null;

export function getPusherInstance() {
    if (!pusherInstance) {
        pusherInstance = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        });
    }
    return pusherInstance;
}

export function getPusherChannel() {
    const pusherInstance = getPusherInstance();
    let channel = pusherInstance.channels.channels['ranked-overlay'];

    if (!channel) {
        return pusherInstance.subscribe('ranked-overlay');
    }

    return channel;
}
<template>
    <div class="register-form bg-gray-200">
        <div class="flex items-center justify-center min-h-screen">
            <div class="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <!-- Register Form -->
                <form id="registerForm" class="space-y-6">
                    <h2 class="text-2xl font-bold text-center">Register</h2>

                    <div class="bg-red-200 p-4 rounded-lg" v-if="!!errorMessage">
                        <p class="font-bold">Error!</p>
                        <p>
                            Something went wrong, please get in touch if issue persists.
                        </p>
                    </div>

                    <div>
                        <label for="register-email" class="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            v-model="email"
                            type="email"
                            id="register-email"
                            name="email"
                            required
                            class="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            autocomplete="email"
                            @blur="v$.email.$touch()"
                        >
                        <p class="text-red text-xs" v-if="v$.email.$errors[0]">{{ v$.email.$errors[0].$message }}</p>
                    </div>

                    <div>
                        <label for="register-password" class="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            v-model="password"
                            type="password"
                            id="register-password"
                            name="password"
                            required
                            ref="password"
                            class="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            autocomplete="suggestPassword"
                            @blur="v$.password.$touch()"
                        >
                        <p class="text-red text-xs" v-if="v$.password.$errors[0]">{{ v$.password.$errors[0].$message }}</p>
                    </div>

                    <div>
                        <label for="register-password-confirmation" class="block text-sm font-medium text-gray-700">
                            Confirm Password
                        </label>
                        <input
                            v-model="confirmPassword"
                            type="password"
                            id="register-password-confirm"
                            name="confirm-password"
                            required
                            class="block w-full mt-1 px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            autocomplete="suggestPassword"
                            @blur="v$.confirmPassword.$touch()"
                        >
                        <p class="text-red text-xs" v-if="v$.confirmPassword.$errors[0]">{{ v$.confirmPassword.$errors[0].$message }}</p>
                    </div>

                    <button
                        type="submit"
                        class="w-full py-2 mt-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                        @click.prevent="register()"
                        :disabled="loading === true"
                    >
                        Register
                        <i class="fa-solid fa-spinner fa-spin" v-if="loading === true"></i>
                    </button>

                    <p class="text-center text-sm text-gray-600">Got an account already?
                        <router-link to="/" class="text-blue-500 hover:underline">Login here</router-link>.
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/http-common'
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required, sameAs, minLength} from "@vuelidate/validators";

export default {
    name: 'RegisterView',
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            email: null,
            password: null,
            confirmPassword: null,
            errorMessage: false,
            loading: false,
        }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage('Email is a required field.', required),
                email: helpers.withMessage('Invalid email address', email)
            },
            password: {
                required: helpers.withMessage('Password is a required field.', required),
                length: minLength(8),
                valid: helpers.withMessage('Password complexity not met. Must contain lowercase, uppercase, numbers and a symbol.', (value) => {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    return containsUppercase && containsLowercase && containsNumber && containsSpecial
                })
            },
            confirmPassword: {
                sameAsPassword: helpers.withMessage('Passwords don\'t match', sameAs(this.password)),
            }
        }
    },
    methods: {
        register() {
            this.v$.$reset();
            this.v$.email.$touch();
            this.v$.password.$touch();
            this.v$.confirmPassword.$touch();

            if (this.v$.$errors.length > 0) {
                return false;
            }

            this.errorMessage = false;
            apiClient.post('/register', {
                email: this.email,
                password: this.password
            })
                .then((res) => {
                    if (res.status !== 200) {
                        this.errorMessage = true;
                    } else {
                        this.$router.push({ name: 'login', query: { registerSuccess: true }});
                    }
                })
                .catch(() => {
                    this.errorMessage = true;
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    }
}
</script>
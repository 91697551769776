import {defineStore} from "pinia";

export const authStore = defineStore({
  id: 'auth',
  state: () => ({
    token: null,
    userId: null,
  }),
  actions: {
    setToken(token) {
      this.token = token;
      localStorage.setItem('token', token);
    },
    setUserId(userId) {
      this.userId = userId;
      localStorage.setItem('userId', userId);
    },
    loadToken() {
      const storedToken = localStorage.getItem('token');
      const storedUserId = localStorage.getItem('userId');
      if (storedToken) {
        this.token = storedToken;
      }
      if (storedUserId) {
        this.userId = storedUserId;
      }
    },
    logout() {
      this.token = null;
      this.userId = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    },
    isAuthenticated() {
      return !!this.token && !!this.userId;
    },
  },
});
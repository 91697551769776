<template>
    <button @click="logout">Logout</button>
</template>

<script>
import {authStore} from "@/stores/AuthStore";

export default {
    created() {
        // Called when the component is created (before mounting)
        this.logout();
    },
    methods: {
        logout() {
            const auth = authStore();
            auth.logout();
            this.$router.push({ name: 'login' });
        }
    }
}
</script>
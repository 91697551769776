import './assets/css/style.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia());
app.use(router);

// Load token on app start
import { authStore } from './stores/AuthStore';
const auth = authStore();
auth.loadToken();

app.mount('#app')
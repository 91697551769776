<template>
    <RouterView />
</template>


<script>
import {RouterView} from 'vue-router'

export default {
    components: [RouterView],
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import {authStore} from '@/stores/AuthStore';
import LoginView from '@/components/Login.vue'
import RankedOverlay from '@/components/RankedOverlay.vue'
import RegisterView from '@/components/Register.vue'
import LogOut from "@/components/LogOut.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/login',
      redirect: '/',
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView,
      meta: { requiresAuth: false }
    },
    {
      path: '/',
      name: 'login',
      component: LoginView,
      props: route => ({
        registerSuccess: route.query.registerSuccess === 'true',
      }),
      meta: { requiresAuth: false }
    },
    {
      path: '/:userId/overlay',
      name: 'overlay',
      component: RankedOverlay,
      meta: { requiresAuth: false }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/components/Dashboard.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogOut,
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const auth = authStore();
  const isAuthenticated = auth.isAuthenticated();

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login'); // Redirect to login if route requires authentication and user is not authenticated
  } else if (to.name === 'login' && isAuthenticated) {
    next('/dashboard'); // Redirect to dashboard if trying to access login route and user is authenticated
  } else {
    next(); // Allow navigation to the requested route
  }
});

export default router;